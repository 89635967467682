<script lang="ts">
	import { buttonDisabled } from '$lib/stores/generalStore';
	import { gotoButtonClick } from '$lib/utils/helpers';

	export let gotoRoute: any;
	export let gotoButtonText: string;
</script>

<button
	on:click={() => gotoButtonClick(gotoRoute)}
	class="btn btn-secondary"
	disabled={$buttonDisabled}>{gotoButtonText}</button
>

<style lang="scss">
	button:disabled {
		background: lightgrey;
	}
</style>
